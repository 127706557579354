nav {
  height: 3.5rem;
  box-shadow: var(--shadow-2);
  width: 100%;
  background: var(--surface1);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  margin: 0;
  z-index: 100;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-1);
    font-weight: 700;
  }

  li {
    color: var(--text1);
    margin: 1rem;
  }
  li:hover {
    color: var(--text2);
  }

  @media screen and (min-width: 768px) {
    ul {
      flex-direction: row;
    }
    li {
      margin: 0 1rem;
    }
  }
}

.logo-wrapper {
  padding: 0.5em;

  svg {
    position: relative;
    height: 90%;
  }
}

.nav-logo-path {
  fill: var(--brand);
}

#menuBtn {
  position: relative;
  width: 3rem;
  height: 3rem;
  background-color: transparent;
  z-index: 100;
}
//hamburger curtesy of Mikael Ainalem https://codepen.io/ainalem/pen/LJYRxz

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  height: 100%;
}
.hamRotate.active {
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: var(--brand);
  stroke-width: 5.5;
  stroke-linecap: round;
}
.ham1 .top {
  stroke-dasharray: 40 139;
}
.ham1 .bottom {
  stroke-dasharray: 40 180;
}
.ham1.active .top {
  stroke-dashoffset: -98px;
}
.ham1.active .bottom {
  stroke-dashoffset: -138px;
}

// menu responsive functionality-----------------
.menu-container {
  position: fixed;
  top: 0;
  right: 0;
  padding: var(--size-8);
  background-color: var(--surface2);
  height: 100vh;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -ms-transition: transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
  z-index: 98;
}
.menu-active {
  transform: translateX(105%);
  // visibility: hidden; 
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -ms-transition: transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
}

@media screen and (min-width: 768px) {
  #hamBtn, #menuBtn {
    display: none;
  }
  .menu-active {
    visibility: visible;
    transform: translateX(0);
    right: 0;
    top: 0.5rem;
    height: fit-content;
    padding: 0;
    background-color: inherit;
  }
}
