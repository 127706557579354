@mixin btn() {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  padding: 0.5em 3em;
  margin-right: 0.5rem;
  border-radius: var(--radius-round);
  font-weight: 700;
  font-size: 0.75rem;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.btn-primary {
  @include btn();
  background-color: var(--brand);
  color: var(--text4);
  border: var(--border-size-2) solid var(--brand);

  &:hover {
    color: var(--text4);
    background-color: var(--surface4);
  }
}

.btn-outlined-light {
  @include btn();
  background-color: transparent;
  color: var(--text5);
  border: var(--border-size-2) solid var(--text5);

  &:hover {
    background-color: var(--surface2);
  }

}

 .btn-outlined-dark {
  @include btn();
  background-color: transparent;
  color: var(--brand);
  border: var(--border-size-2) solid var(--brand);

  &:hover {
    background-color: var(--brand);
    color: var(--text4);
  }
}


// codepen by Ian Lunn:
// https://codepen.io/IanLunn/pen/AxBReL?editors=1100

@mixin hideTapHighlightColor() {
	//Prevent highlight colour when element is tapped
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
@mixin hardwareAccel() {
	//Improve performance on mobile/tablet devices
	transform: translateZ(0);
}


.button {
  margin: 3em 0;
  padding: 1em 2em 1em 3em;
  cursor: pointer;
  background: var(--text5);
  text-decoration: none;
  color: var(--surface5);
  border-radius: 2em;

  i {
    margin-left: 1em;
  }
}

.outline-outward {
	display: inline-block;
	$outerBorderWidth: 4px;
	$innerBorderWidth: 4px;

	position: relative;

	@include hideTapHighlightColor();
	@include hardwareAccel();

	&:before {
		content: '';
		position: absolute;
		border: var(--text5) solid $outerBorderWidth;
    border-radius: 2.5em;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition-duration: .3s;
		transition-property: top right bottom left;
	}

	&:hover:before {
		top: -($outerBorderWidth + $innerBorderWidth);
		right: -($outerBorderWidth + $innerBorderWidth);
		bottom: -($outerBorderWidth + $innerBorderWidth);
		left: -($outerBorderWidth + $innerBorderWidth);
	}
}
