html {
  /* 2022 */
  --very-peri: var(--very-peri-main);
  --text1-peri: var(--very-peri-900);
  --text2-peri: var(--very-peri-main);
  --text3-peri: var(--very-peri-600);
  --text4-peri: var(--very-peri-100);
  --text5-peri: var(--very-peri-100);
  --surface1-peri: var(--very-peri-100);
  --surface2-peri: var(--very-peri-200);
  --surface3-peri: var(--very-peri-300);
  --surface4-peri: var(--very-peri-500);
  --surface-shadow-peri: 239 30% 20%;
  --shadow-strength-peri: 2%;
  --logo-opacity: 0.3;

  /* 2023 */
  --viva-magenta: var(--viva-magenta-main);
  --text1-viva-magenta: var(--viva-magenta-900);
  --text2-viva-magenta: var(--viva-magenta-main);
  --text3-viva-magenta: var(--viva-magenta-600);
  --text4-viva-magenta: var(--viva-magenta-100);
  --text5-viva-magenta: var(--viva-magenta-100);
  --surface1-viva-magenta: var(--viva-magenta-100);
  --surface2-viva-magenta: var(--viva-magenta-200);
  --surface3-viva-magenta: var(--viva-magenta-300);
  --surface4-viva-magenta: var(--viva-magenta-500);
  --surface-shadow-viva-magenta: 346 30% 20%;
  --shadow-strength-viva-magenta: 2%;
  --logo-opacity: 0.3;

  /* dark */
  --dark-main: var(--stone-5);
  --text1-dark: var(--gray-1);
  --text2-dark: var(--gray-3);
  --text3-dark: var(--gray-2);
  --text4-dark: var(--gray-9);
  --text5-dark: var(--gray-0);
  --surface1-dark: var(--gray-9);
  --surface2-dark: var(--gray-9);
  --surface3-dark: var(--gray-6);
  --surface4-dark: var(--gray-5);
  --surface5-dark: var(--gray-8);
  --surface-shadow-dark: 200 50% 3%;
  --shadow-strength-dark: 80%;
  --logo-opacity: 0.1;
}

:root {
  color-scheme: light;

  --very-peri-main: hsl(239, 29%, 54%);
  --very-peri-100: hsl(239, 26%, 93%);
  --very-peri-200: hsl(239, 60%, 92%);
  --very-peri-300: hsl(239, 39%, 83%);
  --very-peri-400: hsl(239, 33%, 73%);
  --very-peri-500: hsl(239, 31%, 63%);
  --very-peri-600: hsl(238, 34%, 38%);
  --very-peri-900: hsl(239, 40%, 20%);

  --viva-magenta-main: hsl(346, 66%, 44%);
  --viva-magenta-100: hsl(346, 100%, 97%);
  --viva-magenta-200: hsl(346, 85%, 94%);
  // --viva-magenta-100: hsl(35, 100%, 97%);
  // --viva-magenta-200: hsl(35, 79%, 89%);
  --viva-magenta-300: hsl(346, 51%, 79%);
  --viva-magenta-400: hsl(346, 52%, 66%);
  --viva-magenta-500: hsl(346, 52%, 55%);
  --viva-magenta-600: hsl(346, 70%, 21%);
  --viva-magenta-900: hsl(345, 50%, 2%);

  /* set defaults */
  --brand: var(--viva-magenta);
  --text1: var(--text1-viva-magenta);
  --text2: var(--text2-viva-magenta);
  --text3: var(--text3-viva-magenta);
  --text4: var(--text4-viva-magenta);
  --text5: var(--text5-viva-magenta);
  --surface1: var(--surface1-viva-magenta);
  --surface2: var(--surface2-viva-magenta);
  --surface3: var(--surface3-viva-magenta);
  --surface4: var(--surface4-viva-magenta);
  --surface5: var(--viva-magenta);
  --surface-shadow: var(--surface-shadow-viva-magenta);
  --shadow-strength: var(--shadow-strength-viva-magenta);
  --logo-opacity: 0.3;
  
  //marquee variables
  --marquee-width: 100vw;
  --marquee-height: 10vh;
  --marquee-elements-displayed: 12;
  --marquee-element-width: calc(
    var(--marquee-width) / var(--marquee-elements-displayed)
  );
  --marquee-animation-duration: calc(var(--marquee-elements) * 3s);

  @media screen and (max-width: 2000px) {
    --marquee-elements-displayed: 8;
  }

  @media screen and (max-width: 950px) {
    --marquee-elements-displayed: 6;
  }

  @media screen and (max-width: 600px) {
    --marquee-elements-displayed: 4;
  }
}

// @media (prefers-color-scheme: dark) {
//   :root {
//     color-scheme: dark;

//     --brand: var(--very-peri-dark);
//     --text1: var(--text1-dark);
//     --text2: var(--text2-dark);
//     --text3: var(--text3-dark);
//     --text4: var(--text4-dark);
//     --surface1: var(--surface1-dark);
//     --surface2: var(--surface2-dark);
//     --surface3: var(--surface3-dark);
//     --surface4: var(--surface4-dark);
//     --surface-shadow: var(--surface-shadow-dark);
//     --shadow-strength: var(--shadow-strength-dark);
//     --logo-opacity: 0.1;
//   }
// }

[color-scheme="dark"] {
  color-scheme: dark;

  --brand: var(--dark-main);
  --text1: var(--text1-dark);
  --text2: var(--text2-dark);
  --text3: var(--text3-dark);
  --text4: var(--text4-dark);
  --text5: var(--text5-dark);
  --surface1: var(--surface1-dark);
  --surface2: var(--surface2-dark);
  --surface3: var(--surface3-dark);
  --surface4: var(--surface4-dark);
  --surface5: var(--surface5-dark);
  --surface-shadow: var(--surface-shadow-dark);
  --shadow-strength: var(--shadow-strength-dark);
  --logo-opacity: 0.1;
}

[color-scheme="very-peri"] {
  color-scheme: light;

  --brand: var(--very-peri);
  --text1: var(--text1-peri);
  --text2: var(--text2-peri);
  --text3: var(--text3-peri);
  --text4: var(--text4-peri);
  --text5: var(--text5-peri);
  --surface1: var(--surface1-peri);
  --surface2: var(--surface2-peri);
  --surface3: var(--surface3-peri);
  --surface4: var(--surface4-peri);
  --surface5: var(--very-peri);
  --surface-shadow: var(--surface-shadow-peri);
  --shadow-strength: var(--shadow-strength-peri);
  --logo-opacity: 0.3;
}

[color-scheme="viva-magenta"] {
  color-scheme: light;
  --brand: var(--viva-magenta);
  --text1: var(--text1-viva-magenta);
  --text2: var(--text2-viva-magenta);
  --text3: var(--text3-viva-magenta);
  --text4: var(--text4-viva-magenta);
  --text5: var(--text5-viva-magenta);
  --surface1: var(--surface1-viva-magenta);
  --surface2: var(--surface2-viva-magenta);
  --surface3: var(--surface3-viva-magenta);
  --surface4: var(--surface4-viva-magenta);
  --surface5: var(--viva-magenta);
  --surface-shadow: var(--surface-shadow-viva-magenta);
  --shadow-strength: var(--shadow-strength-viva-magenta);
  --logo-opacity: 0.3;
}

$size-1: var(--size-3);
$size-2: var(--size-5);
$size-3: var(--size-7);

.mt-1 {
  margin-top: $size-1;
}
.mt-2 {
  margin-top: $size-2;
}
.mt-3 {
  margin-top: $size-3;
}
.pt-1 {
  padding-top: $size-1;
}
.pt-2 {
  padding-top: $size-2;
}
.pt-3 {
  padding-top: $size-3;
}
*:focus {
  outline-color: var(--brand);
}
