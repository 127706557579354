


.switch input {
  position: absolute;
  opacity: 0;
}


.switch {
  display: inline-block;
  font-size: var(--size-5);
  height: 1em;
  width: 2em;
  background: var(--surface3);
  border-radius: 1em;
}

.switch div {
    z-index: 1;
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: var(--brand);
  box-shadow: var(--shadow-2);
  -webkit-transition: all 300ms;
     -moz-transition: all 300ms;
          transition: all 300ms;
}

.switch input:checked + div {
  -webkit-transform: translate3d(100%, 0, 0);
     -moz-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
}
