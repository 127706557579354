@use "../abstracts/" as a;
$hero-logo: clamp(65em, 80vw, 100rem);

header {
  z-index: 0;
  position: relative;
  min-height: calc(100vh - 3.5rem);
  @include a.flex(column, space-between, center);
  text-align: center;
  margin: 0 auto;
}

.name-section {
  text-align: center;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-top: 40vh;
  }
}

.hero-logo-img {
  position: absolute;
  z-index: -1;
  opacity: var(--logo-opacity);
  width: $hero-logo;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero-bottom {
  width: 100%;
  padding: var(--size-3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;
}

.bottom-right {
  display: flex;
  align-items: center;

  .social {
    display: none;
    @media screen and (min-width: 650px) {
      display: block;
      padding: 0 1rem;

      div {
        display: inline-block;
        height: 2rem;
        width: 2rem;
        background-color: var(--brand);
        border-radius: var(--radius-round);
      }
      i {
        padding: 0.5rem;
        color: var(--text4);
      }
    }
  }
}
.bottom-left {
  height: 3rem;
  width: 3rem;
  background-color: var(--brand);
  border-radius: var(--radius-round);

  i {
    color: var(--text4);
    padding: 1rem;
  }
  @media screen and (max-width: 650px) {
    display: none;
  }
}

/* radio */
.theme-title {
  position: absolute;
  top: -40px;
  color: var(--text1);
      font-weight: bold;
      font-size: 1rem;
}
form {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 25rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .bottom-center {
    border: none;
    width: 100%;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    // .radio-container {
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    //   margin: 0 1rem;
    // }
  }
  input[type="radio"] {
    display: none;
    + label {
      position: relative;
      color: var(--text1);
      font-weight: bold;
      font-size: 0.8rem;
      cursor: pointer;
      user-select: none;
      padding-left: 20px;
      padding-right: 20px;
      &::before {
        transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
        content: "";
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: var(--brand);
        border: 0px solid var(--brand);
        font-size: 0;
        position: absolute;
        bottom: 250%;
        left: 50%;
        transform: translate(-50%, 50%);
      }

      &::after {
        transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
        content: "";
        width: 0;
        height: 0;
        border-radius: 50%;
        background-color: var(--brand);
        position: absolute;
        bottom: 250%;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
    &:checked + label {
      &::before {
        background-color: transparent;
        width: 40px;
        height: 40px;
        border-width: 2px;
      }

      &::after {
        width: 30px;
        height: 30px;
      }
    }
  }
}
