@use "../abstracts/" as a;

.about {
  @include a.flex(column, center, center);
  width: 100%;
  padding: var(--size-5);
  margin-top: var(--size-5);

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.photo {
  padding: var(--size-1);
  background-color: var(--surface5);

  img {
    inline-size: var(--size-5);
    aspect-ratio: var(--ratio-square);
    border-radius: var(--radius-round);
    border: var(--border-size-3) solid var(--surface4);
    width: var(--size-fluid-10);
  }
}

.about-text {
  padding: var(--size-5);
  width: 100%;

  h2 {
    color: var(--text5);
    text-align: center;
    margin-bottom: var(--size-4);
  }
  p {
    color: var(--text5);
    margin: 0;
    width: 100%;
    font-size: clamp(1rem, 1.5vw, 1.1rem);
    text-align: justify;
    line-height: var(--font-lineheight-4);
  }
  .btn-container {
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    margin-left: var(--size-7);

    h2 {
      text-align: left;
    }
    .btn-container {
      text-align: left;
    }
  }
}
