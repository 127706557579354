@use 'abstracts/' as a;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
//   cursor: crosshair;
}
body{
    background-color: var(--surface1);
    overflow-x: hidden;
  }
  
  .site-wrapper {
    width: 100%;
    overflow-x: hidden;
    position:relative;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}
.container {
  max-width: 1320px;
  z-index: 2;
}

.wrapper-light{
  background-color: var(--surface1);
  width: 100%;
}

.wrapper-dark{
  background-color: var(--surface5);
  width: 100%;
}

.noise-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 5000;
    width: 100%;
    height: 100%;
    background-repeat: repeat;
    background-position: 0 0;
    background-size: 250px 250px;
    opacity: .4;
    pointer-events: none;
}
