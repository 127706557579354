@use "../abstracts/" as a;

.skills-section {
    width: 100%;
    padding: 1rem 2rem;
    @include a.flex(column, center, center);

    h2 {
        color: var(--text1);
        margin-top: 2rem;
    }

    h3 {
        margin-top: 2rem;
        margin-bottom: 0.5rem;
    }
}
.skills-icons {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    justify-items: center;
    
    @media screen and (min-width: 650px) {
        grid-template-columns: repeat(5, 1fr);
    }
    @media screen and (min-width: 950px) {
        width: 60%;
    }
}
.icon {
    font-size: 2.5rem;
    color: var(--surface4);
    display: flex;
    flex-direction: column;
    text-align: center;
    transition: all 0.2s cubic-bezier(0.41, 0, 0.25, 1.01);
    
    &:hover{
        transform: scale(1.3);
        text-shadow: 0px 6px 15px hsla(239, 100%, 10%, 0.2);
    }

    span {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        font-weight: 700;
        font-size: 0.75rem;
        text-transform: uppercase;
    }
}