@mixin flex($direction: "column", $justify-content: "center", $align-items: "center") {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify-content;
    align-items: $align-items;
}

.flex-column-center {
    @include flex(column, center, center);
}
.flex-row-center{
    @include flex(row, center, center)
}